import { Component, Vue, Prop } from "vue-property-decorator";
import debounce from "lodash.debounce";
import {
  isEmpty,
  getSymbolCurrency,
  isPositiveNumber,
  copyTextClipboard,
  getPaymentMethodLogo,
} from "@helpers";
import { UsdtTransferPaymentMethod } from "@/types/billing";
import { blockchainModule } from "@store/namespaces";
import { BlockchainActions } from "@store/modules/blockchain/types";
import { HtmlElementId } from "@/types/element";

@Component({
  components: {
    QrCode: () => import("@/components/QrCode/QrCode.vue"),
  },
})
export default class UsdtTransfer extends Vue {
  @Prop({ type: Object, required: true })
  private readonly data!: UsdtTransferPaymentMethod;

  @Prop({ type: Boolean, default: false })
  private readonly isFirstRefillForUser!: boolean;
  @blockchainModule.Action("fetchBlockchainCommission")
  private readonly fetchBlockchainCommissionAction!: BlockchainActions["fetchBlockchainCommission"];

  private form = {
    amountOut: 200,
    amountIn: 200,
    comission: 0,
    amountInLoading: false,
  };

  private get htmlElementId() {
    return {
      usdtTransferConverter: HtmlElementId.usdtTransferConverter,
      usdtTransferWallet: HtmlElementId.usdtTransferWallet,
    };
  }

  private get comission() {
    return this.isFirstRefillForUser ? 0 : this.data.comission;
  }

  private get fixedComission() {
    return this.isFirstRefillForUser ? 0 : this.data.fixedComission;
  }

  private get currencySymbol() {
    return getSymbolCurrency(this.data.currency);
  }

  private get trcWallet() {
    return this.data.externalFields.TRC;
  }

  private get paymentMethodLogo() {
    return getPaymentMethodLogo(this.data.id);
  }

  private copyTextClipboard(val: string) {
    this.$notify({
      type: "info",
      title: this.$vuetify.lang.t("$vuetify.info.copied"),
    });
    copyTextClipboard(val);
  }

  private get fieldRules() {
    return {
      amount: (v: string) => {
        if (isEmpty(v)) {
          return this.$vuetify.lang.t("$vuetify.errors.required");
        }

        if (!isPositiveNumber(v)) {
          return this.$vuetify.lang.t("$vuetify.errors.positive_number");
        }

        if (+v < this.data.minAmount) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_amount",
            `${this.data.minAmount} USDT`
          );
        }

        return true;
      },
    };
  }

  private created() {
    this.form.amountOut = this.data.minAmount;
    this.form.comission = this.comission;
  }

  private initAmountOutWatcher() {
    const onChange = debounce(async (amountOut: number) => {
      try {
        const { rate, amount } = await this.fetchBlockchainCommissionAction({
          amount: amountOut,
        });

        this.form.comission = rate;

        const validAmountOut =
          this.fieldRules.amount(this.form.amountOut.toString()) === true;

        if (validAmountOut && amountOut !== this.form.amountOut) {
          onChange(this.form.amountOut);
        } else {
          this.form.amountIn = !this.comission ? amountOut : amount;
          this.form.amountInLoading = false;
        }
      } catch {
        this.form.amountInLoading = false;
      }
    }, 700);

    this.$watch(
      () => {
        return this.form.amountOut;
      },

      (amountOut) => {
        const validAmountOut =
          this.fieldRules.amount(amountOut.toString()) === true;

        if (this.form.amountInLoading || !validAmountOut) return;

        this.form.amountInLoading = true;

        onChange(amountOut);
      },
      { immediate: true }
    );
  }

  private mounted() {
    this.initAmountOutWatcher();
  }
}
